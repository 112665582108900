<script>
  export default {
    name: 'week-stats-info',
    props: {
      stateName: {
        type: String,
        required: true,
      },
      stateValue: {
        type: String,
        required: true,
      },
    },
  };
</script>
