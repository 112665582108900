<template>
  <v-col class="pa-0">
    <div class="text-center">
      <div class="state-name">{{ stateName }}</div>
      <div class="state-value">{{ stateValue }}</div>
    </div>
  </v-col>
</template>

<script>
  import WeekStatsInfoMixin from '@/components/profile/week-stats-info/WeekStatsInfo.mixin.vue';
  export default {
    mixins: [WeekStatsInfoMixin],
  };
</script>

<style lang="css" scoped>
  .state-name {
    font-size: 12px;
    font-weight: 400;
  }
  .state-value {
    font-size: 12px;
    font-weight: 600;
  }
</style>
